import "./App.css";
import Imran from "./components/Imran";

export default function App() {
  const helloMessage = new Date().getFullYear();
  const name = "imran";
  return (
    <div>
      <h1 className="heading">Hello, {name}!</h1>
      <h1>Copyright {helloMessage}</h1>
      <ul>
        <li>One</li>
        <li>Two</li>
        <li> <Imran/> </li>
      </ul>
    </div>
  );
}
